import type { DefaultColors } from 'tailwindcss/types/generated/colors';
import resolveConfig from 'tailwindcss/resolveConfig';
import { config } from '../config/tailwind.config';

export type ColorVariants = {
  DEFAULT: string;
  dark: string;
  darker: string;
  light: string;
  lighter: string;
};

export type GrayVariants = {
  1: string;
  2: string;
  3: string;
  4: string;
  5: string;
  6: string;
  7: string;
  8: string;
  9: string;
};

export interface Colors extends DefaultColors {
  asphalt: ColorVariants;
  brand: ColorVariants;
  error: ColorVariants;
  gray: GrayVariants & DefaultColors['gray'];
  info: ColorVariants;
  lime: ColorVariants & DefaultColors['lime'];
  orange: ColorVariants & DefaultColors['orange'];
  purple: ColorVariants & DefaultColors['purple'];
  red: ColorVariants & DefaultColors['red'];
  success: ColorVariants;
  teal: ColorVariants & DefaultColors['teal'];
  transparent: DefaultColors['transparent'];
  violet: ColorVariants & DefaultColors['violet'];
  warning: ColorVariants;
  yellow: ColorVariants & DefaultColors['yellow'];
}

/**
 * @external https://tailwindcss.com/docs/configuration#referencing-in-java-script
 * @description Now we make our config available to the rest of the app via
 * JS directly. This is useful when we want to reference a color or other
 * Tailwind attribute in a component, but not via a style property.
 */
export const configTailwind = resolveConfig(config);
export const colors = configTailwind.theme?.colors as unknown as Colors;
export const fontFamily = configTailwind.theme?.fontFamily;
export const fontSize = configTailwind.theme?.fontSize;
export const theme = configTailwind.theme;
